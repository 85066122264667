import React from 'react';
import WaistManual from "../../components/WaistManual";
import useWaistCNImages from "../../hooks/useWaistCNImages";

const locale = "cn";
export default () => {
	const images = useWaistCNImages();
	return (
		<>
			<WaistManual locale={locale} images={images}/>
		</>
	);
}
